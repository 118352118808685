import { getBackgroundCSS, getBorderCSS, getColorsCSS, getSpaceCSS, getTypoCSS } from '../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { slides = [], sliderWidth, sliderHeight, pageOnDevice = {}, prevNextOnDevice = {}, sliderBG = {}, sliderPadding = {}, pageColor, pageWidth, pageHeight, pageBorder, prevNextColor, sliderAlign, titleTypo, descTypo, btnTypo, btnPadding, btnBorder } = attributes;

	const mainSl = `#csbContentSlider-${clientId}`;
	const sliderSl = `${mainSl} .csbContentSlider`;
	const gSlideSl = `${sliderSl} .slide`;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
				${getTypoCSS('', titleTypo)?.googleFontLink}
				${getTypoCSS('', descTypo)?.googleFontLink}
				${getTypoCSS('', btnTypo)?.googleFontLink}
				${getTypoCSS(`${gSlideSl} .sliderTitle`, titleTypo)?.styles}
				${getTypoCSS(`${gSlideSl} .sliderDescription`, descTypo)?.styles}
				${getTypoCSS(`${gSlideSl} .sliderBtn`, btnTypo)?.styles}

				${mainSl} .csbSliderWrapper{
					text-align: ${sliderAlign};
					height: ${sliderHeight};
				}
				${sliderSl}{
					width: ${sliderWidth};
					height: ${sliderHeight};
					${getBackgroundCSS(sliderBG)};
					padding: ${getSpaceCSS(sliderPadding)};
				}

				${gSlideSl} .sliderBtn{
					padding: ${getSpaceCSS(btnPadding)};
					${getBorderCSS(btnBorder)}
				}

				${sliderSl} .swiper-pagination .swiper-pagination-bullet{
					background: ${pageColor};
					width: ${pageWidth};
					height: ${pageHeight};
					${getBorderCSS(pageBorder)}
				}
				
				${sliderSl} .swiper-button-prev, ${sliderSl} .swiper-button-next{
					color: ${prevNextColor};
				}

				@media (max-width: 768px) {
					${sliderSl} .swiper-pagination{
						display: ${!pageOnDevice?.tablet ? 'none' : 'block'};
					}
					${sliderSl} .swiper-button-prev, ${sliderSl} .swiper-button-next{
						display: ${!prevNextOnDevice?.tablet ? 'none' : 'flex'};
					}
				}
				@media (max-width: 576px) { 
					${sliderSl} .swiper-pagination{
						display: ${!pageOnDevice?.mobile ? 'none' : 'block'};
					}
					${sliderSl} .swiper-button-prev, ${sliderSl} .swiper-button-next{
						display: ${!prevNextOnDevice?.mobile ? 'none' : 'flex'};
					}
				}
			`.replace(/\s+/g, ' ')
		}} />

		<style>
			{slides.map((item, index) => {
				const { background, border = {}, childPositions = [{ left: 5.8, top: 11 }, { left: 5.8, top: 22 }, { left: 5.8, top: 33 }], titleColor, descColor, btnColors, btnHovColors } = item;

				const slideSl = `${sliderSl} .slide-${index}`;
				const contentChild = document.querySelector(`${slideSl} .sliderContent`)?.children || [];

				let childElPositionCSS = '';
				for (let i = 0; i < contentChild.length; i++) {
					childPositions[i] = childPositions[i] || { left: 5.8, top: (i + 1) * 11 };

					childElPositionCSS += `${slideSl} .sliderContent > *:nth-child(${i + 1}){
						top: ${childPositions[i].top}%; left: ${childPositions[i].left}%;
					}`;
				}

				return `${slideSl}{
						${getBackgroundCSS(background)}
						${getBorderCSS(border) || ''}
					}
					${slideSl} .sliderTitle{
						color: ${titleColor || '#fff'};
					}
					${slideSl} .sliderDescription{
						color: ${descColor || '#fff'};
					}
					${slideSl} .sliderBtn{
						${getColorsCSS(btnColors) || 'color: #fff; background: #4527a4;'}
					}
					${slideSl} .sliderBtn:hover{
						${getColorsCSS(btnHovColors) || 'color: #fff; background: #8344c5;'}
					}
					${childElPositionCSS}
				`.replace(/\s+/g, ' ');
			})}
		</style>
	</>;
}
export default Style;