const sliderOptions = (attributes, initialSlide, isBackend = false) => {
	const { columns, columnGap, isLoop, isTouchMove, speed, isAutoplay, autoplayOptions, freeModeOptions, effect, keyboardOptions, isMousewheel, isPageClickable, isPageDynamic } = attributes;

	return {
		// Optional parameters
		direction: 'horizontal',
		initialSlide,
		slidesPerView: columns?.mobile,
		breakpoints: {
			// when window width is >= 576px
			576: { slidesPerView: columns?.tablet },
			// when window width is >= 768px
			768: { slidesPerView: columns?.desktop },
		},
		spaceBetween: columnGap,
		loop: isLoop,
		allowTouchMove: isBackend ? false : isTouchMove,
		grabCursor: isBackend ? false : isTouchMove,
		// autoplay: false,
		autoplay: isAutoplay && !isBackend ? { ...autoplayOptions, delay: autoplayOptions.delay * 1000 } : false,
		freeMode: freeModeOptions,
		speed: speed * 1000,
		effect,
		fadeEffect: { crossFade: false },
		cubeEffect: { shadow: false },
		creativeEffect: {
			prev: {
				shadow: true,
				translate: ['-120%', 0, -500],
			},
			next: {
				shadow: true,
				translate: ['120%', 0, -500],
			}
		},
		keyboard: keyboardOptions,
		mousewheel: isMousewheel,
		allowSlideNext: true,
		allowSlidePrev: true,
		autoHeight: false,
		notificationClass: null,

		// Controllers
		pagination: {
			el: '.swiper-pagination',
			clickable: isPageClickable,
			dynamicBullets: isPageDynamic
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}
	}
}
export default sliderOptions;